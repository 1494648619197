import React, { Component } from "react";

const initData = {
  heading: "Explore the Vibrant World of Asian Street Food",
  content:
    "Dive into the vibrant world of Asian street food with Street Food Journeys. Our app serves as your personal guide, leading you through bustling markets and food alleys to savor Asia's most iconic dishes and hidden gems. Explore detailed dish information, enjoy personalized recommendations, and benefit from offline access. Immerse yourself in the authentic tastes of Asia – one flavorful bite at a time.",
  btnText: "Get Started",
  btnTextTwo: "Get Started",
  welcomeThumb: "/img/welcome_mockup_2.png",
  buttons: [
    // {
    //   id: 1,
    //   linkClass: "btn btn-bordered",
    //   iconClass: "icofont icofont-brand-android-robot dsp-tc",
    //   iconText: "GET IT ON",
    //   iconSpan: "Google Play",
    // },
    {
      id: 2,
      linkClass: "btn btn-bordered",
      iconClass: "icofont icofont-brand-apple dsp-tc",
      iconText: "Download on the",
      iconSpan: "App Store",
    },
  ],
};

class welcomeEight extends Component {
  state = {
    initData: {},
  };
  componentDidMount() {
    this.setState({
      initData: initData,
    });
  }
  render() {
    return (
      <section id="home" className="section welcome-area bg-inherit h-100vh">
        <div className="shapes-container">
          <div className="shape bg-shape" />
        </div>
        <div className="container h-100">
          <div className="row align-items-center h-100">
            {/* Welcome Intro Start */}
            <div className="col-12 col-md-7 col-lg-6">
              <div className="welcome-intro text-right">
                <h1>{this.state.initData.heading}</h1>
                <p className="my-4">{this.state.initData.content}</p>

                {/* Store Buttons */}
                <div className="button-group store-buttons">
                  {initData.buttons.map((item, idx) => {
                    return (
                      <a
                        key={`ao_${idx}`}
                        href="https://apps.apple.com/app/id6447338077"
                        className={item.linkClass}
                      >
                        <i className={item.iconClass} />
                        <p className="dsp-tc">
                          {item.iconText}
                          <br /> <span>{item.iconSpan}</span>
                        </p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6">
              {/* Welcome Thumb */}
              <div
                className="welcome-thumb text-center"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1000}
              >
                <img src={this.state.initData.welcomeThumb} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default welcomeEight;
